import React, { useContext, useEffect, useState } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine'

import Spiner from '../Spiner/Spiner'
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';


//transalate
import { useTranslation, Trans } from 'react-i18next';
import { Label } from 'recharts';
import { RangePickerProps } from 'antd/es/date-picker';
import { getLocationByDate } from '../../Api/Subsidiary';
import { getLocationByDate as getLocationByDateOrganization } from '../../Api/Organization';

import MapAppMultiple from '../MapApp/MapAppMultiple';
import MapApp from '../MapApp/MapApp';
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

interface Props {
    id?: String

  
  }

const LocationSection = ({id}:Props) => {
  const { t } = useTranslation();
  const params = useParams();

  const [datesSelected, setDatesSelected] = useState<boolean>(false)
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<{lat: number, lng: number , timestamp:string}[]>([]);


  const [from, setFrom] = useState<string> ("")
  const [to, setTo] = useState<string> ("")
  const [status, setStatus] = useState<"error"|""|"warning"> ("")

  const { error, sendError, sesion } = useContext(PromiseSesionContext);

  function fechasEnRango(fecha1: Date, fecha2: Date): boolean {
    const milisegundosPorDia = 1000 * 3600 * 24;
    const diferenciaEnMilisegundos = Math.abs(fecha2.getTime() - fecha1.getTime());
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / milisegundosPorDia);
    console.log("diferencia en dias ", diferenciaEnDias)
    return diferenciaEnDias <= 30;
}

  useEffect(() => {
    if(from!="" && to!=""){
      console.log("fechas", from , to)

      let date1 = new Date(from)
  
      let date2 = new Date(to)

      var now_utc = date1.toUTCString().substr(0, 25);


      console.log("fechas", date1, date2, now_utc)
      console.log(new Date(now_utc));
      console.log(date1.toISOString());
      console.log(date2.toISOString());

      if(fechasEnRango(date1, date2)){
        setStatus("")
        getData()
      }
      else{
        setStatus("error")

      }
    }
    }, [from, to]);

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
  
  
        const startDate = dayjs().subtract(1, 'month').startOf('day');
        const endDate = dayjs().endOf('day');
  
        return current && (current > endDate);
      };

      const getData = async () =>{
        try {
          setLoading(true)

          const res = sesion.context.rol==="Sucursal" ? await getLocationByDate(id?.toString() || 0, from, to) : await  getLocationByDateOrganization(id?.toString() || 0, params.idSucursal || 0, from, to)
    
          console.log("respuesta ", res)

          setLocations(res.locations)
          //res.temperatureDetails.reverse()
          //setData(res)
          //generateSubArrays(res?.temperatureDetails)
    
          setLoading(false)
          
        }
        catch(err){
          sendError({type: "ERROR", data:{message:t('basico.errorRegistros')}})
          setLoading(false)
    
        }
      }

  return (
    <>
      {
        datesSelected ?

        <>
          <Spiner loading={loading} >
            <div style={{width:"100%", height:"400px"}}>
            <MapAppMultiple locations={locations} ></MapAppMultiple>
            </div>
          </Spiner>

        </>


        : <>
            <div style={{padding:"10px 0", display:"flex", flexDirection:"column", gap:"5px"}}>

              <RangePicker
                //showTime
                status={status}
                placeholder={[t('basico.inicio'), t('basico.fin')]}
                onCalendarChange={(v)=>{
                  console.log("dateee ", v)
                  if(v?.[0]){
                    let date1 = `${v?.[0].year()}-${v?.[0].month()+1 < 10 ?"0": ""}${v?.[0].month()+1}-${v?.[0].date()<10 ?"0": ""}${v?.[0].date()}T00:00:00`
                    let isoDate = new Date(date1).toISOString();
                    setFrom(isoDate)
                  }
                  if(v?.[1]){

                    let date2 = `${v?.[1].year()}-${v?.[1].month()+1 < 10 ?"0": ""}${v?.[1].month()+1}-${v?.[1].date()<10 ?"0": ""}${v?.[1].date()}T23:59:59`
                    let isoDate2 = new Date(date2).toISOString();
                    setTo(isoDate2)
                  }
                }}
                 disabledDate={disabledDate} />

                 {/* <div>
                  <TimePicker.RangePicker style={{width:"100%"}} />
                 </div> */}

                 {status=="error" &&
                 <span style={{width:"100%", color:"red", fontSize:"12px"}}>
                  {t('activo.mensajeReporte')}
                 </span>}

                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px", gap:"8px"}}>
                  <Button type="primary"  
                  style={{marginLeft:"10px"}}  
                  disabled={from=="" || to=="" || status=="error"}      
                  onClick={() => {
                      if (from!="" && to!="" && status!="error") {
                        setDatesSelected(true)
                      }
                    }}>
                    {t('basico.siguiente')}
                  </Button>
                  </div>

           
            </div>
         </>
      }





    </>
  )
}

export default LocationSection
